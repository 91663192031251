import FetchService from "./fetch.service";
import localStorageService from './localStorage.service';

class SupportService {
  async submitSupportRequest(formData) {
    try {
      // Retrieve company info from local storage
      const companyInfo = localStorageService.getCompanyInfo();
      const companyName = companyInfo?.name || "N/A";
       
      formData.append('company_name', companyName);

      const fetchService = new FetchService();
      const token = localStorageService.getToken();
      const response = await fetchService("/api/support/", {
        method: 'POST',
        headers: { 'Authorization': `Token ${token}` },
        body: formData,
        credentials: 'include',
      });
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SupportService();