import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// Custom plugin to add margin between legend and chart
export const customMarginPlugin = {
    id: 'customMarginPlugin',
    beforeInit: function (chart) {
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += chartsLegendMargin; // Adds the extra margin
        };
    },
};
export const chartsLegendMargin = 25;

export const errorsChartOptions = (dashboardType, maxValue) => {
    let maxIndex = {};
    const ticksObj = {
        font: {
            family: "Inter"
        },
    };

    if (maxValue < 10) {
        maxIndex = { max: 10 };
        ticksObj.stepSize = 1;
    }

    return {
        responsive: true, // Set to true to allow the chart to resize with the container
        maintainAspectRatio: false, // Set to false to allow the chart to have its own aspect ratio
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: dashboardType === "error-monitoring" ? 'Error Percentage (%)' : 
                          dashboardType === "site-performance" ? 'Average execution time (ms)' : 'Total Calls',
                },
                grid: {
                    display: false, // Hide Y-axis grid lines
                },
                ticks: ticksObj,

                ...maxIndex
            },
            x: {
                title: {
                    display: false,
                    text: 'Timestamp'
                },
                grid: {
                    display: false, // Hide X-axis grid lines
                },
                ticks: {
                    font: {
                        family: "Inter"
                    },
                }
            }
        },
        elements: {
            line: {
                tension: 0.4, // Adjust curve tension (0.4 for moderate curve)
                borderWidth: 4, // Adjust line thickness
            },
            point: {
                radius: 0, // Remove data points
            },
        },
        animation: {
            x: {
                duration: 2000,
                from: 0
            },
            y: {
                duration: 2000,
                from: 500
            }
        },
        plugins: {
            legend: {
                position: "top",
                align: "start",
                labels: {
                    boxWidth: 30,
                    boxHeight: 5,
                    useBorderRadius: true,
                    borderRadius: 1,
                    font: {
                        family: "Inter"
                    },
                },
            },
            customMarginPlugin: {
                marginTop: 50,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            if (dashboardType === "error-monitoring") {
                                label += context.parsed.y.toFixed(2); // Display value with 2 decimals
                                label += '%'; // Add percentage symbol for error-monitoring
                            } else {
                                label += Math.round(context.parsed.y); // Display value without decimals
                            }
                        }
                        return label;
                    }
                }
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false
        },
        hover: {
            mode: 'index',
            intersect: false
        }
    }
};

export const consentChartOptions = () => {
    return {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percentage (%)',
                },
                grid: {
                    display: false, // Hide Y-axis grid lines
                },
                ticks: {
                    callback: function(value) {
                        return value; // Add percentage symbol to y-axis labels
                    },
                    font: {
                        family: "Inter"
                    },
                },
            },
            x: {
                title: {
                    display: false,
                    text: 'Timestamp'
                },
                grid: {
                    display: false, // Hide X-axis grid lines
                },
                ticks: {
                    font: {
                        family: "Inter"
                    },
                }
            }
        },
        elements: {
            line: {
                tension: 0.4, // Adjust curve tension (0.4 for moderate curve)
                borderWidth: 4, // Adjust line thickness
            },
            point: {
                radius: 0, // Remove data points
            },
        },
        plugins: {
            legend: {
                position: "top",
                align: "start",
                labels: {
                    boxWidth: 30,
                    boxHeight: 5,
                    useBorderRadius: true,
                    borderRadius: 1,
                    font: {
                        family: "Inter"
                    },
                },
            },
            customMarginPlugin: {
                marginTop: 50,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toFixed(2) + '%'; // Format tooltip label as percentage
                        }
                        return label;
                    }
                }
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false
        },
        hover: {
            mode: 'index',
            intersect: false
        }
    }
};