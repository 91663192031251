import React, {useState, useEffect} from 'react';

export const TimezoneDropdown = (props) =>{

    const {choosenTimezone, timezones, timezoneName ,handleTimezoneChange }= props;
    let buttonTimeZone;
    const [ curTimeZoneName, setCurTimeZoneName ] = useState(timezoneName);

    
    if (choosenTimezone){

        if(curTimeZoneName == "Berlin"){
            buttonTimeZone = timezones["CEST2"];

        } else if(curTimeZoneName == "Amsterdam"){
            buttonTimeZone = timezones["CEST"];
        } else {
            buttonTimeZone = timezones[choosenTimezone];
        }
    }  else {
        buttonTimeZone = "Choose timezone";
    }

    useEffect(() => {

        if (choosenTimezone){

            if(curTimeZoneName == "Berlin"){
                buttonTimeZone = timezones["CEST2"];

            } else if(curTimeZoneName == "Amsterdam"){
                buttonTimeZone = timezones["CEST"];
            } else {
                buttonTimeZone = timezones[choosenTimezone];
            }
        }  else {
            buttonTimeZone = "Choose timezone";
        }
        
    },[curTimeZoneName]);


    return(
        <div>
        <h3 className="modal-category mb-2">Select your timezone</h3>
        <p>Which timezone is applicable for your business?</p>
        <div>
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle btn-light text-start"
                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {buttonTimeZone}
                </button>
                <ul className="dropdown-menu" aria-labelledby="timezone_select">
                    {Object.keys(timezones).map(key =>
                        <li className="pointer-on-hover" key={key} onClick={
                            () => {handleTimezoneChange(key); setCurTimeZoneName(key)}
                        }>
                            <div className="dropdown-item">{timezones[key]}</div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
    )
}