import { React, useState, useEffect, useRef } from 'react';
import { Select, Button } from 'antd';
import { UploadOutlined, WarningFilled, SettingOutlined, CloudOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {PlusCircle, DashCircle } from 'react-bootstrap-icons';
import { InfoCircleDiv } from '../../components/elements/InfoHoverableDiv';
import {
    Form,
    Input,
    Upload,
    Switch,
    Space,
} from 'antd';
import colors from '../../styles/_colors.scss';


const regionLists = [
    "asia-east1 (Taiwan)",
    "asia-northeast1 (Tokyo)",
    "asia-northeast2 (Osaka)",
    "europe-north1 (Finland)",
    "europe-southwest1 (Madrid)",
    "europe-west1 (Belgium)",
    "europe-west4 (Netherlands)",
    "europe-west8 (Milan)",
    "europe-west9 (Paris)",
    "me-west1 (Tel Aviv)",
    "us-central1 (Iowa)",
    "us-east1 (South Carolina)",
    "us-east4 (Northern Virginia)",
    "us-east5 (Columbus)",
    "us-south1 (Dallas)",
    "us-west1 (Oregon)",
    "africa-south1 (Johannesburg)",
    "asia-east2 (Hong Kong)",
    "asia-northeast3 (Seoul, South Korea)",
    "asia-southeast1 (Singapore)",
    "asia-southeast2 (Jakarta)",
    "asia-south1 (Mumbai, India)",
    "asia-south2 (Delhi, India)",
    "australia-southeast1 (Sydney)",
    "australia-southeast2 (Melbourne)",
    "europe-central2 (Warsaw, Poland)",
    "europe-west10 (Berlin)",
    "europe-west12 (Turin)",
    "europe-west2 (London, UK)",
    "europe-west3 (Frankfurt, Germany)",
    "europe-west6 (Zurich, Switzerland)",
    "me-central1 (Doha)",
    "me-central2 (Dammam)",
    "northamerica-northeast1 (Montreal)",
    "northamerica-northeast2 (Toronto)",
    "southamerica-east1 (Sao Paulo, Brazil)",
    "southamerica-west1 (Santiago, Chile)",
    "us-west2 (Los Angeles)",
    "us-west3 (Salt Lake City)",
    "us-west4 (Las Vegas)"
];

const regionItems = regionLists.map((region) => {
    const key = region.split(" (")[0];
    return key;
});

const infoText = `
    It looks like this feature is part of our premium offerings. 
    If you’re interested in accessing it, please reach out to us, 
    and we’ll be happy to assist you with upgrading your plan.
`;


const GoogleCloudConfigForm = (props) => {
    const {
        tagMonitorSubscription,
        saveFunction,
        cloudRunData,
        setModalSaveStatus,
    } = props;

    const [form] = Form.useForm();
    const [configData, setConfigData] = useState(cloudRunData?.config_data);

    const [enableCloudRun, setEnableCloudRun] = useState(configData?.current_config?.cloud_run_monitoring || false);
    const [serverErrorState, setServerErrorState] = useState(configData?.current_config?.ss_error_monitoring || false);
    const [inputValue, setInputValue] = useState(configData?.current_config?.project_number);
    const [resourcesList, setResourcesList] = useState([""]);

    const initCloudRunState = useRef([]);
    const initServerErrorState = useRef([]);
    const initialResourcesListRef = useRef([]);

    const serverErrorLoggingDisabled = tagMonitorSubscription === "essentials" || tagMonitorSubscription === "advanced";

    useEffect(() => setConfigData(cloudRunData?.config_data), [cloudRunData]);       

    useEffect(() => {
        if (configData) {
            const initialResources = JSON.parse(configData?.current_config?.cloud_run_resources_list?.replace(/'/g, '"') || "[]") || [""];
            setResourcesList(initialResources);

            if (!Object.keys(initCloudRunState).find(elem => elem === "current") || initCloudRunState.current.length === 0)
                initCloudRunState.current = enableCloudRun;

            if (!Object.keys(initServerErrorState).find(elem => elem === "current") || initServerErrorState.current.length === 0)
                initServerErrorState.current = serverErrorState;

            if (initialResourcesListRef.current.length === 0) initialResourcesListRef.current = initialResources;

            form.setFieldsValue({
                project_number: configData?.current_config?.project_number,
                items: initialResources,
                ss_error_monitoring: configData?.current_config?.ss_error_monitoring,
                cloud_run_monitoring: configData?.current_config?.cloud_run_monitoring,
                upload: []
            });
        }

        setEnableCloudRun(configData?.current_config?.cloud_run_monitoring || false);
        setServerErrorState(configData?.current_config?.ss_error_monitoring || false);

        setModalSaveStatus(
            enableCloudRun !== initCloudRunState.current
            || serverErrorState !== initServerErrorState.current
            || inputValue !== configData?.current_config?.project_number
        );
    }, [configData]);

    const isResourceEmpty = (resource) => {
        return (
          (!resource.name || resource.name.trim() === '') &&
          (!resource.region || resource.region.trim() === '')
        );
      };
      
      const filterEmptyResources = (resources) =>
        resources.filter(resource => !isResourceEmpty(resource));

      useEffect(() => {
        const filteredCurrentResources = filterEmptyResources(resourcesList);
        const filteredInitialResources = filterEmptyResources(initialResourcesListRef.current);
        const resourcesChanged =
          JSON.stringify(filteredCurrentResources) !== JSON.stringify(filteredInitialResources);
      
        setModalSaveStatus(
          enableCloudRun !== initCloudRunState.current ||
          serverErrorState !== initServerErrorState.current ||
          inputValue !== configData?.current_config?.project_number ||
          resourcesChanged
        );
      }, [enableCloudRun, serverErrorState, inputValue, resourcesList]);

    const onFinish = (values) => {
        if (values["items"]) {
            let trimmedData = values["items"]?.map(item => {
                return {
                    name: item.name.trim(),
                    region: item.region.trim()
                };
            });
            values["items"] = trimmedData;
        }
        saveFunction(values);
    };


    return (
        <div>
            <Form
                name="GoogleCloudConfigForm"
                onFinish={onFinish}
                layout='vertical'
                form={form}
                initialValues={{
                    items: resourcesList,
                    ss_error_monitoring: configData?.current_config?.ss_error_monitoring,
                    cloud_run_monitoring: configData?.current_config?.cloud_run_monitoring,
                    upload: [],
                    remember: true
                }}
            >
            <div>   
                <div>
                    <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center' }}>
                    <SettingOutlined className='mb-1' style={{ color: '#000000', marginRight: '8px', fontSize: '20px' }} />
                        Enable monitoring features
                    </h3>
                </div>
                <br/>
                <div className='modal-category fw-bold d-flex justify-content-between align-items-start w-100'>
                    <div style={{width: "80%"}}> 
                           <p className="modal-category fw-bold" style={{marginBottom: 6}}>Cloud Run monitoring</p>
                           <p>Track performance of your Cloud Run instances</p>
                       </div>
                           <Form.Item
                               name="cloud_run_monitoring"
                               valuePropName="checked"
                               style={{ marginBottom: 0 , marginLeft: 'auto'  }}
                           >
                               <Switch
                                   disabled={configData?.current_config?.cloud_run_monitoring}
                                   onChange={(value) => setEnableCloudRun(value)}
                               />
                           </Form.Item>
                       <p className='ps-2' style={{ lineHeight: "34px", display: "inline-block" }}>
                           {enableCloudRun ? " " : " "}
                       </p>
                   </div>
               </div>

               <div>
                   <div className='modal-category fw-bold d-flex justify-content-between align-items-start w-100'>
                       <div style={{width: "80%"}}>
                           <div style={{ position: "relative" }}>
                           <div className="modal-category fw-bold" style={{marginBottom: 6}}>
                                Server-side error logs
                                {serverErrorLoggingDisabled &&
                                <InfoCircleDiv content={infoText} top="-8.5em" />
                                }
                            </div>
                           </div>
                           <p>
                               Get detailed error reporting on server-side errors
                           </p>
                        </div>
                              
                   
                        <Form.Item
                            valuePropName="checked"
                            name="ss_error_monitoring"
                            style={{ marginBottom: 0, marginLeft: 'auto' }}
                        >
                            <Switch
                                onChange={(value) => setServerErrorState(value)}
                                disabled={configData?.current_config?.ss_error_monitoring || serverErrorLoggingDisabled}
                            />
                        </Form.Item>
                        <p className='ps-2' style={{ lineHeight: "34px", display: "inline-block" }}>
                            {serverErrorState ? " " : " "}
                        </p>
                   </div>
               </div>
               <hr style={{ border: '1px solid', color: colors.ccBlue, width: '100%' }} />

               {enableCloudRun && (
                   <div className='mb-3'>
                    <div className='pt-3' style={{ marginBottom: '16px' }}>
                        <h3 className="modal-category" style={{ marginBottom: '8px' }}>
                        <CloudOutlined style={{ color: '#000000', marginRight: '8px', fontSize: '20px' }}/>
                        Google Cloud Platform details
                        </h3>
                        <p style={{ marginBottom: '16px' }}>
                        Add the details on the Google Platform project, where the GTM tagging server is hosted.
                        </p>
                    </div>
                    <p className="modal-category fw-bold" style={{marginBottom: 6}}>Project ID</p>
                   <Form.Item
                     name="project_number"
                     rules={[{ required: true, message: 'Please add your project number!' }]}
                   >
                     <Input
                       style={{ width: '17rem' }}
                       placeholder='485687865'
                       value={inputValue}
                       onChange={(e) => setInputValue(e.target.value)}
                     />
                   </Form.Item>
                 </div>
               )}
               {(enableCloudRun || serverErrorState) && (
                   <div>
                       <div>
                       <p className="modal-category fw-bold" style={{marginBottom: 6}}>Service account key</p>
                       </div>
                       <p>
                           Create a servive account in the GCP project and upload the JSON file below. <a
                               style={{ color: "#000" }}
                               target="blank"
                               href="https://docs.code-cube.io/tag-monitor/cloud-run-monitoring/#create-service-account-key">
                           </a>
                       </p>
                       {/* Upload Field */}
                       <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <Form.Item
                                name="upload"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e && e.fileList;
                                }}
                                rules={[{ required: true, message: 'Please upload a file!' }]}
                            >
                                <Upload
                                accept='.json'
                                name="logo"
                                action="/upload.do"
                                listType="text"
                                beforeUpload={() => false}
                                >
                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div className="btn btn-sm cc-btn-outline-blue fw-bold" style={{ color: colors.ccBlue, marginBottom: 0 }}>
                                    <UploadOutlined style={{ paddingRight: ".8rem" }} />
                                    upload service account key
                                    </div>
                                    <span style={{ fontStyle: "italic", fontSize: ".8rem", color: "#ff661f", marginTop: '4px' }}>
                                        The file will never be stored in any of our systems.
                                    </span>
                                </div>
                                </Upload>
                            </Form.Item>
                        </div>                     
                       <hr style={{ border: '1px solid', color: colors.ccBlue, width: '100%' }} />
                   </div>
               )}
               
                {enableCloudRun && (
                    <div className='mt-4'>
                    <h3 className="modal-category" style={{ marginBottom: '8px' }}>
                    <i  style={{ color: '#000000', marginRight: '8px' }}>
                        <svg
                            className='mb-1'
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            shapeRendering="geometricPrecision"
                            textRendering="geometricPrecision"
                            imageRendering="optimizeQuality"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            viewBox="0 0 506 511.591"
                        >
                            <path
                                fillRule="nonzero"
                                d="M.041 102.171c-.019-4.703 2.858-9.137 7.523-10.875L249.424.729a11.513 11.513 0 018.144.031l241.001 92.419c5.001 2.4 7.431 6.732 7.431 11.726l-.259 310.616c0 5.047-3.238 9.339-7.751 10.912l-238.822 83.986c-4.718 1.674-7.93 1.476-11.952-.096L7.564 420.501C2.915 418.77.041 414.36.041 409.673H0V103.142l.041-.971zm482.84 18.697l-217.827 84.183v278.787l217.589-76.512.238-286.458zm-229.518-96.94L42.776 102.785l211.532 81.635 207.935-80.36-208.88-80.132zM241.86 483.64V204.394L23.194 120.007v281.675L241.86 483.64z"
                            />
                        </svg>
                    </i>
                        Instance Configuration
                    </h3>
                    <p>
                        <a
                            style={{ color: "#000" }}
                            target="blank"
                            href="https://docs.code-cube.io/tag-monitor/cloud-run-monitoring/#locate-cloud-run-resources">
                        </a>
                    </p>
                    <DynamicInputField setResourcesList={setResourcesList} resourcesList={resourcesList} />
                </div>
                )}
                {(enableCloudRun || serverErrorState) && (
                    <div>
                        {/* Submit Button */}
                        <Form.Item>
                            <Button type="primary" id="orange-btn" htmlType="submit">
                                start configuration
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form>
        </div>
    );
};

const DynamicInputField = (props) => {
    const { resourcesList, setResourcesList } = props;

    const handleAdd = (add, fields) => {
        add();
        const newList = [...resourcesList, { name: "", region: "" }];
        setResourcesList(newList);
    };

    const handleRemove = (name, remove, fields) => {
        remove(name);
        const newList = resourcesList.filter((_, index) => index !== name);
        setResourcesList(newList);
    };

    const handleFieldChange = (index, field, value, fields) => {
        const updatedList = [...resourcesList];
        updatedList[index] = {
            ...updatedList[index],
            [field]: value
        };
        setResourcesList(updatedList);
    };

    return (
        <div className='list-col-2-add'>
            <div className="d-flex flex-row">
                <div style={{flex: '1' }}>
                    <p className="modal-category fw-bold" style={{marginBottom: 6}}>Instance name</p>
                </div>
                <div style={{flex: '1', marginRight: '20px'}}>
                    <p className="modal-category fw-bold" style={{marginBottom: 6}}>Region</p>
                </div>
            </div>

            <Form.List
                name="items"
                rules={[
                    {
                        validator: async (_, names) => {
                            if (!names || names.length < 1) {
                                return Promise.reject(new Error('At least 1 resource name is required'));
                            }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.length === 0 && (
                            <div className="inputs-row-3-col-single d-flex flex-row align-items-center" style={{padding: 0}}>
                                <div style={{flex: '1'}}>
                                    <Form.Item
                                        name={[0, 'name']}
                                        fieldKey={[0, 'name']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input a resource name",
                                            },
                                        ]}
                                        style={{ marginBottom: 0, minWidth: "17rem" }}
                                    >
                                        <Input placeholder="Resource name" />
                                    </Form.Item>
                                </div>

                                <div style={{flex: '1'}}>
                                    <Form.Item
                                        name={[0, 'region']}
                                        fieldKey={[0, 'region']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please select a region",
                                            },
                                        ]}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select placeholder="Select the resource region" style={{ minWidth: "14rem" }} showSearch>
                                            {regionItems.map((item, index) => (
                                                <Select.Option key={`${item}-${index}`} value={item}>
                                                    {item}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{display: 'flex'}}>
                                    {/* Add button for the first field */}
                                    <PlusCircle
                                        className='color-blue ms-2'
                                        size={18}
                                        onClick={() => handleAdd(add)}
                                    />
                                </div>
                            </div>
                        )}
                        {fields.map(({ key, name, ...field }, index) => (
                            <div key={`${field.key}-${index}`} className="inputs-row-3-col-single d-flex flex-row align-items-center" style={{padding: 0}}>
                                <div style={{flex: '1'}}>
                                    <Form.Item
                                        {...field}
                                        name={[name, 'name']}
                                        fieldKey={[key, 'name']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input a resource name",
                                            },
                                        ]}
                                        style={{ marginBottom: 0, minWidth: "17rem" }}
                                    >
                                        <Input
                                            placeholder="Resource name"
                                            onChange={(e) => handleFieldChange(name, 'name', e.target.value, fields)}
                                        />
                                    </Form.Item>
                                </div>

                                <div style={{flex: '1'}}>
                                    <Form.Item
                                        {...field}
                                        name={[name, 'region']}
                                        fieldKey={[key, 'region']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please select a region",
                                            },
                                        ]}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select
                                            placeholder="Select the resource region"
                                            style={{ minWidth: "14rem" }}
                                            showSearch
                                            onChange={(value) => handleFieldChange(name, 'region', value, fields)}
                                        >
                                            {regionItems.map((item, index) => (
                                                <Select.Option key={`${item}-${index}`} value={item}>
                                                    {item}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{display: 'flex'}}>
                                        <DashCircle
                                            className='color-blue ms-2'
                                            size={18}
                                            onClick={() => handleRemove(name, remove, fields)}
                                        />
                                    {index === fields.length - 1 && (
                                        <PlusCircle
                                            className='color-blue ms-2'
                                            size={18}
                                            onClick={() => handleAdd(add, fields)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}

                        <Form.Item>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default GoogleCloudConfigForm;
