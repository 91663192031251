// AppRouter.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './services/auth.service';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/layouts/MainLayout';
import { PasswordReset } from "./pages/password";
import { PasswordChange } from "./pages/password/password-change";

import { MessageProvider, useMessage } from './components/elements/MessageCenter'; // Adjust the import path as necessary

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import './assets/base.css';
import "./styles.scss"
import "./styles_ant_override.scss"

import Home from "./pages/Home";
import { LoginPage } from "./pages/Login";
import { LogoutPage } from "./pages/Logout";

import { TagMonitorConfig } from "./pages/TagMonitorConfig";
import { DataLayerGuardConfig } from "./pages/DataLayerGuardConfig";
import { SitespeedMonitorConfig } from "./pages/SitespeedMonitorConfig";

import { DataLayerGuardDash } from './pages/DataLayerGuardDash';
import { TagMonitorDash } from './pages/TagMonitorDash';

import { AccountSettings } from './pages/AccountSettings';
import { CreateDataset } from './pages/CreateDataset';
import GTM  from './pages/GTM'
import { Support } from "./pages/Support"

const AppRouter = () => {
    useEffect(() => {
        document.title = 'Portal - Code Cube';
    }, []);

    return (
        <Router>
            <AuthProvider>
                <MessageProvider>
                    <MainLayout>
                        <Routes>
                            <Route
                                path="/"
                                element={<ProtectedRoute name="home"><Home /></ProtectedRoute>}
                            />
                            <Route
                                path="/login"
                                element={<ProtectedRoute name="login"><LoginPage /></ProtectedRoute>}
                            />
                            <Route
                                path="/account/login"
                                element={<ProtectedRoute name="login"><LoginPage /></ProtectedRoute>}
                            />
                            <Route
                                path="/logout"
                                element={<ProtectedRoute name="logout"><LogoutPage /></ProtectedRoute>}
                            />
                            <Route
                                path="/password-reset"
                                element={
                                    <ProtectedRoute name="password-reset">
                                        <PasswordReset />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/change-password"
                                element={
                                    <ProtectedRoute name="change-password">
                                        <PasswordChange />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/account_settings"
                                element={<ProtectedRoute name="account_settings"><AccountSettings /></ProtectedRoute>}
                            />
                            <Route
                                path="/datalayer_guard_config"
                                element={
                                    <ProtectedRoute name="datalayer_guard_config">
                                        <DataLayerGuardConfig />
                                    </ProtectedRoute>}
                            />
                            <Route
                                path="/sitespeed_monitor_config"
                                element={
                                    <ProtectedRoute name="sitespeed_monitor_config">
                                        <SitespeedMonitorConfig />
                                    </ProtectedRoute>}
                            />
                            <Route
                                path="/tag_monitor_config"
                                element={
                                    <ProtectedRoute name="tag_monitor_config">
                                        <TagMonitorConfig />
                                    </ProtectedRoute>}
                            />

                            <Route
                                path="/datalayer_guard_dashboard"
                                element={
                                    <ProtectedRoute name="datalayer_guard_dashboard">
                                        <DataLayerGuardDash />
                                    </ProtectedRoute>}
                            />
                            <Route
                                path="/tag_monitor_dashboard"
                                element={
                                    <ProtectedRoute name="tag_monitor_dashboard">
                                        <TagMonitorDash />
                                    </ProtectedRoute>}
                            />
                            <Route
                                path="/create_dataset"
                                element={
                                    <ProtectedRoute name="create_dataset">
                                        <CreateDataset />
                                    </ProtectedRoute>}
                            />
                            <Route
                                path="/invoice_settings"
                                element={
                                    <ProtectedRoute name="invoice_settings">
                                        <CreateDataset />
                                    </ProtectedRoute>}
                            />

                            <Route
                                path="/authorize_user"
                                element={
                                    <ProtectedRoute name="authorize_user">
                                        <GTM />
                                    </ProtectedRoute>}
                            />
                              <Route
                                path="/support_request"
                                element={<ProtectedRoute name="support_request"><Support /></ProtectedRoute>}
                            />
                        </Routes>
                    </MainLayout>
                </MessageProvider>
            </AuthProvider>
        </Router>
    );
};

export default AppRouter;
