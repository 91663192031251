import { React, useState, useEffect } from 'react';
import { Modal, Popover, Button } from 'antd';

import { ActionButton } from '../../../elements/Button';

import "./styles.scss";
import colors from '../../../../styles/_colors.scss';

const defaultPopoverMessage = () => (<div>Please fill in all required fields to proceed</div>);

export const SideMenu = (props) => {
    const {
        globalState,
        setGlobalState,
        modalContent,
        saveFunction,
        modalWidth = "narrow",
        saveStatus,
        confirmationNeeded = false,
        popoverMessage = defaultPopoverMessage,
    } = props;

    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
        saveFunction();
    };
    const handleCancel = () => {
        setOpen(false);
        setGlobalState(false);
    };

    const [curModalContent, setCurModalContent] = useState(<></>);
    useEffect(() => setCurModalContent(modalContent), [modalContent]);

    const slideLeftClass = "slide-left";
    const slideRightClass = "slide-right";
    const fadeInClass = "m-fadeIn";
    const fadeOutClass = "m-fadeOut";

    const [modalState, setModalState] = useState(false);
    const [modalWidthClass, setModalWidthClass] = useState("");
    const [modalStateClasses, setModalStateClasses] = useState([slideRightClass, fadeOutClass].join(" "));
    const [bkgStateClasses, setBkgStateClasses] = useState(fadeOutClass);

    useEffect(() => {
        setModalState(globalState);
        setModalWidthClass(modalWidth === "wide" ? "modal-wide" : "");

        switch (modalState) {
            case true:
                setModalStateClasses([slideLeftClass, fadeInClass].join(" "))
                setBkgStateClasses(fadeInClass);
                break;
            case false:
                setModalStateClasses([slideRightClass, fadeOutClass].join(" "));
                setBkgStateClasses(fadeOutClass);
                break;
        }
    });

    const handleModalClose = () => {
        switch (saveStatus) {
            case 1:
            case true:
                showModal();
                break;
            default:
                setModalState(false);
                setGlobalState(false);
        }
    }

    // hide the scrollbar on the main page when the modal window is open
    useEffect(() => {
        switch (modalState) {
            case true:
                document.body.classList.add('no-scroll');
                break;
            default:
                document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [modalState])

    return (
        <div className="modal-area">
            <div className={`modal-window ${modalWidthClass} ${modalStateClasses}`}>
                <div className="modal-content-cc">
                    <div className="modal-elems">{curModalContent}</div>
                </div>
                <div className="modal-buttons-area">
                    <div className="modal-buttons">
                        <Popover content={saveStatus || !confirmationNeeded ? null : popoverMessage} trigger="hover">
                            <>
                                <ActionButton
                                    size="large"
                                    type="primary"
                                    disabled={!(saveStatus || !confirmationNeeded)}
                                    width={140}
                                    onClickFunction={saveFunction}
                                >
                                    Save
                                </ActionButton>
                            </>
                        </Popover>
                    </div>
                </div>
            </div>
            <div className={`bkg-shad ${bkgStateClasses}`} onClick={handleModalClose}></div>
            <Modal
                open={open}
                centered
                width={430}
                className='modal-pop-up'
                height={80}
                // style={{marginTop: '15em'}}
                title="Save your changes"
                okText="Save Changes"
                cancelText="Discard"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                        <Button key="discard" style={{borderRadius: '14px', backgroundColor: 'rgba(43, 43, 181, 0.15)'}} onClick={handleCancel}>
                            Discard
                        </Button>,
                        <Button key="save" style={{borderRadius: '14px', backgroundColor: colors.ccBlue}} type="primary"  onClick={handleOk}>
                            Save changes
                        </Button>
                ]}
            >
                <p>Do you want to save your changes before leaving this step?</p>
            </Modal>
        </div>
    );
};
