import React, { useState } from 'react';
import '../assets/cssModules/pages/pages.css';
import LoadingScreen from '../../components/elements/LoadingScreen';
import PageHeader from '../../components/elements/PageHeader';
import { Button, Select, message, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import colors from '../../styles/_colors.scss';
import SupportService from '../../services/support.service';
import { Link } from 'react-router-dom';

function MyIcon() {
  return (
    <svg width="31" height="31" id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.46 56.42">
      <defs>
        <style>
          {`
            .cls-1 { fill: #c79127; }
            .cls-2 { fill: #ffce31; }
            .cls-3 { fill: #94989b; }
            .cls-4 { fill: #616466; }
          `}
        </style>
      </defs>
      <path className="cls-4" d="M21.88,46.81c0,1.73,1.4,3.12,3.12,3.12s3.12-1.4,3.12-3.12h-6.25Z"/>
      <path className="cls-2" d="M19.48,39h11.04c.65-3.37,2.74-6.64,4.93-10.08,2.54-3.99,5.17-8.11,5.17-12.12,0-7.58-7.01-13.74-15.62-13.74s-15.62,6.16-15.62,13.74c0,4.01,2.63,8.14,5.17,12.12,2.19,3.43,4.28,6.71,4.94,10.08Z"/>
      <path className="cls-1" d="M19.25,25.6c.03-.17.11-.26.2-.28.05-.02.12-.01.12-.01.03.02.09.05.15.11.26.27.45.72.59,1.13.05.16.1.32.15.48-.33-.06-.64-.19-.84-.39-.29-.27-.45-.7-.38-1.04ZM24.53,22.87c.13-.19.3-.3.47-.3s.34.11.47.3c.15.24.1.57-.05.89-.11.23-.26.45-.42.65-.16-.21-.31-.43-.42-.65-.15-.32-.2-.65-.05-.89ZM29.68,26.54c.15-.41.34-.86.59-1.13.06-.07.12-.1.15-.11,0,0,.08,0,.12.01.09.02.17.11.2.28.06.33-.1.77-.38,1.04-.2.19-.51.33-.84.39.05-.16.09-.32.15-.48ZM20.66,27.75c.11.47.21.93.33,1.38.21.85.41,1.68.6,2.48.72,2.94,1.35,5.46,1.85,7.39h.46c-.41-1.96-.97-4.53-1.64-7.52-.18-.8-.37-1.63-.56-2.48-.1-.42-.19-.84-.29-1.28.65-.14,2.07-.63,3.58-2.26,1.51,1.63,2.93,2.11,3.58,2.26-.1.44-.19.86-.29,1.28-.19.85-.38,1.69-.56,2.48-.67,2.99-1.23,5.56-1.65,7.52h.46c.5-1.94,1.13-4.46,1.85-7.39.19-.8.39-1.62.6-2.48.12-.45.22-.92.33-1.38.59-.04,1.16-.22,1.6-.63.45-.44.69-1.03.58-1.65-.05-.3-.27-.68-.7-.81-.2-.06-.4-.08-.65-.01-.21.08-.36.2-.47.31-.42.46-.57.92-.74,1.38-.08.23-.15.46-.21.69,0,0-.66.05-2.08-.9-.79-.53-1.02-.92-1.25-1.17.25-.27.48-.57.67-.94.1-.2.18-.42.21-.67.03-.25-.02-.53-.16-.77-.14-.24-.35-.46-.68-.59-.14-.05-.3-.07-.46-.07s-.32.02-.46.07c-.33.13-.53.35-.68.59-.14.24-.19.52-.16.77.03.25.11.47.21.67.18.36.42.67.67.94-.23.25-.46.64-1.25,1.17-1.42.96-2.09.9-2.09.9-.06-.23-.13-.46-.21-.69-.17-.46-.32-.92-.74-1.38-.1-.11-.26-.23-.47-.31-.25-.06-.45-.05-.65.01-.43.13-.65.52-.7.81-.11.62.13,1.2.58,1.65.44.4,1,.59,1.59.63Z"/>
      <rect className="cls-3" x="19.42" y="40.53" width="11.2" height="1.44"/>
      <rect className="cls-3" x="20.2" y="43.41" width="9.64" height="1.44"/>
      <rect className="cls-4" x="20.2" y="41.97" width="9.64" height="1.44"/>
      <polygon className="cls-3" points="30.62 40.53 20.2 43.41 20.2 44.84 30.62 41.97 30.62 40.53"/>
      <rect className="cls-3" x="20.98" y="46.28" width="8.08" height="1.44"/>
      <rect className="cls-4" x="20.98" y="44.84" width="8.08" height="1.44"/>
      <polygon className="cls-3" points="29.84 43.41 20.98 46.28 20.98 47.72 29.84 44.84 29.84 43.41"/>
    </svg>
  );
}

const { Option } = Select;

export const Support = () => {
  const [loadingActive, setLoadingActive] = useState(false);
  const [about, setAbout] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [fileList, setFileList] = useState([]);
  const [showThankYou, setShowThankYou] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const productOptions = ["Tag Monitor", "DataLayer Guard"];
  const categoryOptions = [
    "Account management issues",
    "Payment / billing problem",
    "Technical errors",
    "Feature request",
    "Documentation",
    "Other"
  ];

  const maxFileSize = 50 * 1024 * 1024; // 50 MB
  const allowedExtensions = ['.jpg', '.png', '.svg', '.pdf', '.mp4', '.mov'];

  const beforeUpload = (file) => {
    const ext = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
    const isAllowed = allowedExtensions.includes(ext);
    if (!isAllowed) {
      message.error(`${file.name} is not a supported file type.`);
      return Upload.LIST_IGNORE;
    }
    const isLt50M = file.size < maxFileSize;
    if (!isLt50M) {
      message.error(`${file.name} is larger than 50 MB.`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const isFormValid = about && category && description.trim();

  const handleSubmit = async () => {
    if (!isFormValid) {
      alert("Please fill in required fields.");
      return;
    }
    setLoadingActive(true);
    const formData = new FormData();
    formData.append('about', about);
    formData.append('category', category);
    formData.append('description', description);
    fileList.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    try {
      const result = await SupportService.submitSupportRequest(formData);
      setShowThankYou(true);
      setFadeOut(false);
      setAbout('');
      setCategory('');
      setDescription('');
      setFileList([]);
       // After 2.5 seconds, start fade-out effect
       setTimeout(() => {
        setFadeOut(true);
      }, 2500);
      // After 3 seconds, hide the message completely
      setTimeout(() => {
        setShowThankYou(false);
      }, 3000);
    } catch (error) {
      alert('There was an error sending your request.');
    } finally {
      setLoadingActive(false);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <LoadingScreen isActive={loadingActive} />
     
      <PageHeader pageName="Open a request" />
      <div>
      <div style={{ maxWidth: '1200px', textAlign: 'left' }}>
          <div className='row mb-3 mt-3'>
            <div className='col-5 mb-3'>
              <div
                style={{
                  backgroundColor: '#f5f5ff',
                  width: "40rem",
                  borderRadius: "10px",
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1rem'
                }}
              >
                <div style={{ marginRight: '1rem' }}>
                  <MyIcon />
                </div>
                <p style={{ margin: 0 }}>
                  Check our{' '}
                  <Link
                    style={{ textDecoration: 'underline', color: 'inherit' }}
                    to={"https://docs.code-cube.io/"}
                  >
                    documentation
                  </Link>{' '}
                  first for quick answers! Our detailed guides cover most common questions and may save you time.
                </p>
              </div>
            </div>

            <div className="d-flex" style={{ marginBottom: '0.25rem' }}>
              <div style={{ flex: 1 }}>
                <p className="modal-category fw-bold" style={{ marginBottom: '0.25rem' }}>
                  Your request is about *
                </p>
              </div>
              <div style={{ flex: 1, marginRight: '24rem' }}>
                <p className="modal-category fw-bold" style={{ marginBottom: '0.25rem' }}>
                  Category *
                </p>
              </div>
            </div>

            <div className="d-flex mb-4" style={{ gap: '1rem', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <Select
                  placeholder="Select an option"
                  onChange={(value) => setAbout(value)}
                  value={about || "Select an option"}
                  style={{ width: "14rem" }}
                  popupClassName="custom-dropdown"
                >
                  {productOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ flex: 1,  marginRight: '25rem' }}>
                <Select
                  placeholder="Select an option"
                  onChange={(value) => setCategory(value)}
                  value={category || "Select an option"}
                  style={{ width: "14rem" }}
                  popupClassName="custom-dropdown"
                >
                  {categoryOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>


            <div className='mb-4'>
              <p className="modal-category fw-bold mb-1" style={{ marginBottom: 6 }}>
                Description *
              </p>
              <div>
                <TextArea
                  rows={4}
                  placeholder="Please give us a detailed description of your request"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  size='large'
                  style={{ width: "40rem" }}
                />
              </div>
            </div>

            <div className='mb-4'>
              <p className="modal-category fw-bold mb-1" style={{ marginBottom: 6 }}>
                Drop your files or click to upload
              </p>
              <div>
                <Dragger
                  style={{ width: "40rem", backgroundColor: '#fff' }}
                  multiple={true}
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={beforeUpload}
                >
                  <p style={{ fontSize: '0.9rem', color: 'lightgray', marginTop: '0.5rem' }}>
                    {allowedExtensions.join(' ')}
                  </p>
                </Dragger>
              </div>
            </div>

            <div className='mb-3'>
              <Button
                size='middle'
                block
                style={{
                  width: '10rem',
                  backgroundColor: colors.ccBlue,
                  color: '#fff',
                  borderRadius: '25px',
                  border: 'none',
                  fontWeight: 'bold'
                }}
                disabled={!isFormValid}
                onClick={handleSubmit}
              >
                send request
              </Button>
            </div>

            {showThankYou && (
              <div className='col-5 mb-3'>
                <div
                  style={{
                    backgroundColor: '#f5eaff',
                    width: "40rem",
                    borderRadius: "10px",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1rem'
                  }}
                >
                  <div>
                    <p className="modal-category fw-bold mb-2">Thank you for your request!</p>
                    <p>
                      We’ve received your request and will get back to you within 48 hours.
                      If you need immediate assistance, feel free to contact us via support@code-cube.io.
                    </p>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};
