import React, { useEffect, useState } from 'react';
import JSON5 from 'json5';
import { jsonrepair } from 'jsonrepair'
import styles from "./styles.scss";
import { v4 as uuidv4 } from 'uuid';
import { DashCircle, PlusCircle, InfoCircleFill } from 'react-bootstrap-icons';
import { Input } from 'antd';


import { InfoCircleDiv } from '../InfoHoverableDiv';

const generateUniqueId = () => {
    return uuidv4();
}

function isValidRegex(str) {
    try {
        new RegExp(str);
        return true;
    }
    catch (e) {
        if (e instanceof SyntaxError) {
            return false;
        }
        throw e;
    }
}

const dataTypes = ["string", "number", "array", "object", "boolean"];

export const defaultParameterNotation = (props) => {
    const {
        key = "",
        type = "string",
        required = true,
        exatclyMatch = false,
        value = "",
    } = props;

    const isExactlyMatch = dataTypes.indexOf(value) > 0;

    return {
        "uniqueIdentifier": generateUniqueId(),
        "key": key,
        "type": type,
        "required": required,
        "exatclyMatch": isExactlyMatch,
        "value": value,
    };
}

const generateRows = (data, setData) => {

    let TableRows;

    const generateNestedTable = (rawData, depth = 0, recursivelyCalled) => {

        if (typeof (rawData) === "object" && !Array.isArray(rawData)) rawData = [rawData];

        const results = rawData?.map((row, idx) => {
            let isNested = false;
            let value = row.value;
            let key = row.key;
            const uniqueIdentifier = row.uniqueIdentifier;
            let required = row.required;
            let exatclyMatch = row.exatclyMatch;
            let type = row.type;

            const handleChange = (e, uniqueIdentifier, fieldType) => {
                let newValue;

                switch (fieldType) {
                    case "key":
                        newValue = e.target.value;
                        break;
                    case "required":
                        newValue = e.target.dataset.value === "true" ? true : false;
                        break;
                    case "match":
                        newValue = e.target.dataset.value === "true" ? true : false;
                        break;
                    case "dataType":
                        newValue = e.target.dataset.value;
                        break;
                    case "value_custom":
                        newValue = e.target.value;
                        break;
                    default:
                }

                function findObjectById(object, id) {
                    if (object.uniqueIdentifier === id) return object;

                    for (let key in object) {

                        if (typeof object[key] === 'object' && object[key] !== null) {
                            const foundObject = findObjectById(object[key], id);

                            if (foundObject) {
                                if ((typeof (foundObject.value) === "object")) {
                                    foundObject.value = "";
                                    isNested = false;
                                }

                                switch (fieldType) {
                                    case "key":
                                        foundObject.key = newValue;
                                        break;
                                    case "required":
                                        foundObject.required = newValue;
                                        break;
                                    case "match":
                                        foundObject.exatclyMatch = newValue;
                                        break;
                                    case "dataType":
                                        foundObject.type = newValue;
                                        break;
                                    case "value_custom":
                                        foundObject.value = newValue;
                                        break;
                                    default:
                                }

                                if (!foundObject.exatclyMatch) foundObject.value = "";

                                return foundObject;
                            }
                        }
                    }

                    return null;
                }

                const newObj = findObjectById(data, uniqueIdentifier);

                const updatedData = data.map((elem) => {
                    switch (elem.uniqueIdentifier === uniqueIdentifier) {
                        case true:
                            return newObj;
                        default:
                            return elem;
                    }
                });

                setData(updatedData);
            }


            if (typeof value === 'object' && value !== null) {
                isNested = true;
            } else if (typeof value === 'string' && value !== null) {
                if (value.startsWith("{") || value.startsWith("[")) {
                    isNested = true;
                }
                else {
                    isNested = false;
                }
            }

            const handleDeleteRow = (e, uniqueIdentifier) => {

                function removeObjectById(array, id) {

                    return array.filter(item => {
                        if (item.uniqueIdentifier === id) {
                            // If the current item matches the id, exclude it from the new array
                            return false;
                        } else if (Array.isArray(item.value)) {
                            // If the item has a nested array, recursively call this function on the nested array
                            item.value = removeObjectById(item.value, id);
                        }
                        // Include all other items in the new array
                        return true;
                    });
                }

                data = removeObjectById(data, uniqueIdentifier);
                setData(data)
            }


            const handleAddRow = (e, uniqueIdentifier) => {

                function addObjectCopyById(array, id) {

                    return array.flatMap(item => {

                        if (item.uniqueIdentifier === id) {
                            // If the current item matches the id, include it in the new array and add a copy of it with default values
                            const newObject = defaultParameterNotation({
                                "key": "",
                                "type": "string",
                                "required": true,
                                "exatclyMatch": false,
                                "value": ""
                            });

                            const type = item.type;

                            if (["object", "array"].indexOf(type) > -1) {
                                switch (Array.isArray(item.value)) {
                                    case true:
                                        item.value.push(newObject);
                                        break;
                                    default:
                                        item.value = [newObject];
                                }

                                return [item];
                            }
                            else {
                                return [item, newObject];
                            }

                        } else if (Array.isArray(item.value)) {
                            // If the item has a nested array, recursively call this function on the nested array
                            item.value = addObjectCopyById(item.value, id);
                        }

                        // Include all other items in the new array
                        return item;
                    });
                }

                const newUpdatedData = addObjectCopyById(data, uniqueIdentifier);
                setData(newUpdatedData);
            }

            const firstTableRow = idx === 0 && !recursivelyCalled;
            const groupBtnGap = !firstTableRow ? { columnGap: ".3rem" } : {};


            if (isNested) {
                return [
                    <div key={`${uniqueIdentifier}`} className='dlgEventTable d-flex' >
                        {
                            (typeof key !== 'number') && (

                                <div className={`indented-${depth} d-flex dlgEventTable`} >

                                    <div>
                                        <Input name="row_key" type="text" defaultValue={key} onChange={(event) => handleChange(event, uniqueIdentifier, "key")} style={{ height: "100%" }} />
                                    </div>
                                    
                                    <div className="dropdown">
                                        <button className="btn btn-sm btn-light btn-secondary dropdown-toggle" type="button" id={`dropdownMenuButtonMatch_${uniqueIdentifier}`} data-bs-toggle="dropdown" aria-expanded="false">
                                            {type}
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonMatch">
                                            {
                                                dataTypes.map((key, idx) => {
                                                    return (
                                                        <li
                                                            data-value={key}
                                                            onClick={(event) => handleChange(event, uniqueIdentifier, 'dataType')}
                                                            key={`${key}_${idx}`} className="dropdown-item"
                                                        >
                                                            {key}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                    <div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-sm btn-light btn-secondary dropdown-toggle"
                                                type="button"
                                                id={`dropdownMenuButtonRequired_${uniqueIdentifier}`}
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {required ? `required` : `optional`}
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonRequired">
                                                <li data-value={true} onClick={(event) => handleChange(event, uniqueIdentifier, 'required')} className="dropdown-item">required</li>
                                                <li data-value={false} onClick={(event) => handleChange(event, uniqueIdentifier, 'required')} className="dropdown-item">optional</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="action-button-icon group-action-btns" style={groupBtnGap}>
                                        {!firstTableRow &&
                                            <div onClick={(e) => { handleDeleteRow(e, uniqueIdentifier) }}>
                                                <DashCircle size={18}></DashCircle>
                                            </div>
                                        }
                                        <div onClick={(e) => { handleAddRow(e, uniqueIdentifier) }}>
                                            <PlusCircle size={18}></PlusCircle>
                                        </div>
                                    </div>


                                </div>
                            )
                        }
                    </div>,
                    <div key={`${uniqueIdentifier}_${depth}`}>
                        <div colSpan="2">
                            <div>
                                <div>
                                    {generateNestedTable(value, depth + 1, true)}
                                </div>
                            </div>
                        </div>
                    </div>
                ];
            }


            return (

                <div style={{width: "70em"}} className='d-flex dlgEventTable' key={`${uniqueIdentifier}_parent`}>

                    <div className={`indented-${depth}`}>
                        <Input
                            name="event_key"
                            type="text"
                            defaultValue={key}
                            placeholder='event'
                            onChange={(event) => handleChange(event, uniqueIdentifier, "key")}
                            style={{ height: "100%" }}
                        />
                    </div>

                    <div>
                        <div className="dropdown">
                            <button
                                className="btn btn-sm btn-light btn-secondary dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButtonMatch_${uniqueIdentifier}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {type}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonMatch">
                                {
                                    dataTypes.map((key, idx) => {
                                        return (
                                            <li
                                                data-value={key}
                                                onClick={(event) => handleChange(event, uniqueIdentifier, 'dataType')}
                                                key={`${key}_${idx}`} className="dropdown-item"
                                            >
                                                {key}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>


                    <div>
                        <div className="dropdown">
                            <button
                                className="btn btn-sm btn-light btn-secondary dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButtonRequired_${uniqueIdentifier}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"

                            >
                                {required ? `required` : `optional`}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonRequired">
                                <li data-value={true} onClick={(event) => handleChange(event, uniqueIdentifier, 'required')} className="dropdown-item">required</li>
                                <li data-value={false} onClick={(event) => handleChange(event, uniqueIdentifier, 'required')} className="dropdown-item">optional</li>
                            </ul>
                        </div>
                    </div>


                    <div>
                        <div className="dropdown">
                            <button
                                className="btn btn-sm btn-light btn-secondary dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButtonMatch_${uniqueIdentifier}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {exatclyMatch ? `yes` : `no`}

                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonMatch">
                                <li data-value={true} onClick={(event) => handleChange(event, uniqueIdentifier, 'match')} className="dropdown-item">yes</li>
                                <li data-value={false} onClick={(event) => handleChange(event, uniqueIdentifier, 'match')} className="dropdown-item">no</li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <Input
                            name="event_value"
                            type="text" defaultValue={!exatclyMatch ? "" : value}
                            placeholder='value'
                            onChange={(event) => handleChange(event, uniqueIdentifier, 'value_custom')}
                            disabled={["object", "array"].includes(type) || !exatclyMatch && true}
                            status={!exatclyMatch ? "" : (isValidRegex(value) ? "" : "error")}
                            style={{ height: "100%" }}
                        />
                    </div>

                    <div className="action-button-icon group-action-btns" style={groupBtnGap}>
                        {!firstTableRow &&
                            <div onClick={(e) => { handleDeleteRow(e, uniqueIdentifier) }}>
                                <DashCircle size={18}></DashCircle>
                            </div>
                        }
                        <div onClick={(e) => { handleAddRow(e, uniqueIdentifier) }}>
                            <PlusCircle size={18}></PlusCircle>
                        </div>
                    </div>

                </div>
            );

        });

        return results;
    }

    if (data) TableRows = generateNestedTable(data, 0, false);

    return TableRows;
};


export const transformDataObject = (data) => {
    let transformedData;

    const extractKeyValues = (data) => {
        const result = [];

        const processValue = (key, value) => {

            const newRowObj = {
                "uniqueIdentifier": generateUniqueId(),
                "key": key,
                "required": true,
                "exatclyMatch": true
            }

            if (typeof (value) === "object") {
                if (Array.isArray(value)) {
                    newRowObj["type"] = "array";
                } else {
                    newRowObj["type"] = "object";
                }

            } else if (["integer", "float", "number"].includes(value)) {
                newRowObj["type"] = "number";

            } else if ([true, false, "true", "false", "boolean"].includes(value)) {
                newRowObj["type"] = "boolean";

            }
            else {
                newRowObj["type"] = typeof (value);
            }


            if ([null, undefined].includes(value)) {
                newRowObj['value'] = `${value}`;
                newRowObj["type"] = "string";
            } else {
                if (["string", "integer", "float", "boolean", "number"].includes(typeof (value))) {

                    if (typeof value === "string") {
                        if (!value.startsWith("{") || !value.startsWith("[")) {
                            newRowObj['value'] = value;
                        }
                    } else {
                        newRowObj['value'] = value;
                    }

                } else if (typeof value === "object") {
                    newRowObj['value'] = [];

                    if (Array.isArray(value)) {
                        value.forEach((item) => {
                            if ((["string", "integer", "float", "boolean", "number"].includes(typeof (item)))) {
                                newRowObj['value'] = item;

                            } else if (typeof item === "object") {

                                if (Array.isArray(value)) {

                                    value.forEach((item) => {
                                        if ((["string", "integer", "float", "boolean", "number"].includes(typeof (item)))) {
                                            newRowObj['value'] = item;

                                        } else if (typeof item === "object") {

                                            if (Array.isArray(value)) {
                                                value.forEach((item) => {
                                                    if ((["string", "integer", "float", "boolean", "number"].includes(typeof (item)))) {
                                                        newRowObj['value'] = item;

                                                    } else if (typeof item === "object") {
                                                        Object.entries(item).forEach(([key, value]) => {
                                                            const processValueChildList = processValue(key, value);

                                                            const keyExists = newRowObj['value'].find(elem => elem?.key === processValueChildList?.key);

                                                            if (!keyExists) newRowObj['value'].push(processValueChildList);
                                                        });
                                                    }
                                                })

                                            } else {

                                                Object.entries(value).forEach(([key, value]) => {
                                                    const processValueChildList = processValue(key, value);
                                                    newRowObj['value'].push(processValueChildList);
                                                });
                                            }

                                        }
                                    })

                                } else {

                                    Object.entries(value).forEach(([key, value]) => {
                                        const processValueChildList = processValue(key, value);
                                        newRowObj['value'].push(processValueChildList);
                                    });
                                }
                            }
                        })

                    } else {
                        Object.entries(value).forEach(([key, value]) => {
                            const processValueChildList = processValue(key, value);
                            newRowObj['value'].push(processValueChildList);
                        });

                    }
                }
            }
            return newRowObj;

        };


        if (data) {
            Object.entries(data).forEach(([key, value]) => {
                const prossedData = processValue(key, value);
                result.push(prossedData);
            });

        }


        return result;
    };

    if (data) {

        const hasUniqueIdentifier = (obj) => {
            // This function checks if the data has been already formatted or not
            if (obj.hasOwnProperty('uniqueIdentifier')) {
                return true;
            }

            for (let key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    if (hasUniqueIdentifier(obj[key])) {
                        return true;
                    }
                }
            }

            return false;
        }

        const dataAlreadyFormatted = hasUniqueIdentifier(data);

        // If data has already been formatted return data otherwise call the 
        // extractKeyValues function to modify the data
        if (dataAlreadyFormatted) {
            transformedData = data;

        } else {
            if (Array.isArray(data)) {
                transformedData = extractKeyValues(data[0]);
            } else {
                transformedData = extractKeyValues(data);
            }
        }
    }

    return transformedData;
}



export const NestedObjectTable = (props) => {

    const data = props.data ? props.data : [];

    const { updateTemplateFunction } = props;
    const { setEditedEventTemplateNewVersion } = props;

    const [parsedData, setParsedData] = useState(null);
    const [updatedData, setUpdatedData] = useState(null);


    const infoContentText = {
        "parameter": "Add the parameter (object) name.",
        "type": "Select the type of value from the provided options. **Please keep in mind that if you intend to create either an object or an array, you must specify either 'array' or 'object' accordingly.",
        "required": "Determine whether the field is required or not.",
        "match": "Determine whether the value should exactly match a specific value or not.",
        "value": "This field accepts regular expressions.",
    }


    // Effect to update parsedData when data prop changes
    useEffect(() => {
        let dataObj = null;

        switch (data && data.length > 0) {
            case true:
                const repairedJson = data && data !== '' ? jsonrepair(data) : '';
                const parsed = repairedJson !== '' ? JSON5.parse(repairedJson) : {};
                const transformedDataObj = transformDataObject(parsed);

                dataObj = transformedDataObj;

                break;

            default:
                const newObject = defaultParameterNotation({
                    "key": "",
                    "type": "string",
                    "required": true,
                    "exatclyMatch": false,
                    "value": ""
                });

                data.push(newObject);

                dataObj = data;
        }

        updateTemplateFunction(dataObj);
        setEditedEventTemplateNewVersion(dataObj);
        setParsedData(dataObj);
    }, [data]);


    // Effect to update updatedData when parsedData changes
    useEffect(() => {
        if (parsedData) {
            setUpdatedData(parsedData);
            setEditedEventTemplateNewVersion(parsedData);
        }
    }, [parsedData]);


    const updateRawData = (updatedJsonData) => {
        setParsedData(updatedJsonData)
        updateTemplateFunction(updatedJsonData);
        setEditedEventTemplateNewVersion(updatedJsonData);
    }


    return (
        <div className='mt-3'>
            <div className='d-flex heading-row pb-3'>

                <div>
                    <span className='pe-1'>Parameter</span>
                    <InfoCircleDiv content={infoContentText.parameter} top="-3em" />
                    <br></br>
                    <span>Parameter name</span>


                </div>

                <div style={{ left: 45 }}>
                    <span className='pe-1'>Format</span>
                    <InfoCircleDiv content={infoContentText.type} top="-7.5em" />
                    <br></br>
                    <span>Type of parameter</span>

                </div>
                <div style={{ left: 25 }}>
                    <span className='pe-1'>Required?</span>
                    <InfoCircleDiv content={infoContentText.required} top="-4em" />
                    <br></br>
                    <span>Parameter required?</span>

                </div>
                <div style={{ left: -8 }}>
                    <span className='pe-1'>Check for value?</span>
                    <InfoCircleDiv content={infoContentText.match} top="-4em" />
                    <br></br>
                    <span>Should value exactly <br></br> match a specific value?</span>

                </div>
                <div style={{ left: -53 }}>
                    <span className='pe-1'>Value</span>
                    <InfoCircleDiv content={infoContentText.value} top="-4em" />
                    <br></br>
                    <span>If yes, what should the value be?</span>
                </div>
            </div>

            <div className='page-separator-wide'></div>
            <div>
                {updatedData && data && generateRows(updatedData, updateRawData)}
            </div>

        </div>

    );
};
