import React, {useEffect, useState} from 'react';
import { InfoCircleFilled } from '@ant-design/icons';



export const InfoCircleDiv = (props)=>{

    const { content } = props;
    const { top } = props;

    const [showContent, setShowContent] = useState(false);


    const handleMouseEnter = () => {
        setShowContent(true);

    }

    const handleMouseLeave = () => {
        setShowContent(false);
    }


    return(
        <span>
            {   showContent  &&
                <div className='hoverable-div-parent' style={{ top: top}}>
                    <p className='hover-content' style={{ textAlign: "left" }}>
                        { content }
                    </p>
                </div>
            }

            <InfoCircleFilled
                size={15} 
                className='info-icon'
                onMouseEnter={() => {handleMouseEnter()}}
                onMouseLeave={() => {handleMouseLeave()}}
            />
        </span>
    )


}