import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import DashboardService from '../../services/dashboard.service';
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../services/localStorage.service';
import { AuthContext } from '../../services/auth.service';

/**
 * Home page component
 * 
 * @returns {ReactNode} Component with main page content
 */
const Home = () => {
    const { companyInfo } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            // Send a request to check if the session is active
            const session = await DashboardService.checkSession();
            const sessionData = await session?.json();
            const sessionExists = sessionData?.session_exists;

            if (!sessionExists) {
                localStorageService.clearLocalStorage();
                navigate('/login');
            }
        })();
    });

    const [dlgStatus, setDlgStatus] = useState("");
    const [ssmStatus, setSsmStatus] = useState("");
    const [tmStatus, setTmStatus] = useState("");

    useEffect(() => {
        setDlgStatus(companyInfo?.datalayer_guard ? "" : "inactive");
        setSsmStatus(companyInfo?.sitespeed_monitor ? "" : "inactive");
        setTmStatus(companyInfo?.tag_monitor ? "" : "inactive");
    }, [companyInfo])

    return (
        <div className="container row">
            <div className="col-12 text-start">
                <h2>Welcome to Code Cube Portal!</h2>
                <br />
                <section className="panels-area">
                    <div className="panels-rows mb-5">
                        <p className="p-main-text">
                            Here you can find your real-time insights via the dashboards, configure the Code Cube products or update your personal information.<br /><br />
                            Any questions? Please <span style={{ 'textDecoration': 'underline' }}>contact us</span>.
                        </p>
                    </div>
                </section>
                <section className="panels-area">
                    <div className="panels-rows">
                        <h3 className="sub-headings flex-container">
                        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M20 8L16.0811 12.1827C15.9326 12.3412 15.8584 12.4204 15.7688 12.4614C15.6897 12.4976 15.6026 12.5125 15.516 12.5047C15.4179 12.4958 15.3215 12.4458 15.1287 12.3457L11.8713 10.6543C11.6785 10.5542 11.5821 10.5042 11.484 10.4953C11.3974 10.4875 11.3103 10.5024 11.2312 10.5386C11.1416 10.5796 11.0674 10.6588 10.9189 10.8173L7 15" 
                                stroke="black" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                            />
                        </svg>
                            Dashboards
                        </h3>  
                    </div>
                    <div className="panels-cols">
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">Tag Monitor</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/tag_monitor_dashboard"
                                        className={`btn orange-btn-sm btn-sm py-1 px-3 ${tmStatus}`}>
                                        DASHBOARD
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">DataLayer Guard</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/datalayer_guard_dashboard"
                                        className={`btn orange-btn-sm btn-sm py-1 px-3 ${dlgStatus}`}>
                                        DASHBOARD
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="panels-area">
                    <div className="panels-rows">
                        <h3 className="sub-headings flex-container">
                            <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6L13 6M13 6C13 7.65686 14.3431 9 16 9C17.6569 9 19 7.65685 19 6C19 4.34315 17.6569 3 16 3C14.3431 3 13 4.34315 13 6ZM7 14L19 14M7 14C7 15.6569 5.65685 17 4 17C2.34315 17 1 15.6569 1 14C1 12.3431 2.34315 11 4 11C5.65685 11 7 12.3431 7 14Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Configuration
                        </h3>
                    </div>
                    <div className="panels-cols">
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">Tag Monitor</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/tag_monitor_config"
                                        className={`btn purpule-btn-sm btn-sm py-1 px-3 ${tmStatus}`}>
                                        CONFIGURE
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">DataLayer Guard</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/datalayer_guard_config"
                                        className={`btn purpule-btn-sm btn-sm py-1 px-3 ${dlgStatus}`}>
                                        CONFIGURE
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">Sitespeed Monitor</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/sitespeed_monitor_config"
                                        className={`btn purpule-btn-sm btn-sm py-1 px-3 ${ssmStatus}`}>
                                        CONFIGURE
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Home;
