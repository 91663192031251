import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import "./styles.scss";

const MenuCategory = (props) => {
    const {
        categoryIcon,
        categoryName,
        links,
        hovered,
        hoverOutSidebar,
    } = props;

    const location = useLocation();
    const [expandedCategory, setExpandedCategory] = useState(false);
    const [expandedItem, setExpandedItem] = useState(null);

    const handleCategoryClick = () => setExpandedCategory(!expandedCategory);
    
    const handleItemClick = (itemName, event) => {
        event.preventDefault();
        setExpandedItem(expandedItem === itemName ? null : itemName);
    };

    // Updated isSubLinkActive function with safe checks
    const isSubLinkActive = (to) => {
        if (!to) return false;
        const searchParams = new URLSearchParams(location.search);
        const currentView = searchParams.get('view') || 'client';
        return to.toLowerCase() === currentView.toLowerCase();
    };

    const getActiveView = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('view') || 'client';
    };


    const menuElems = links.map(elem => {
        if (elem.children) {
            return (
                <div key={elem.name} className={`sidebar-elems-link ${elem?.active ? "" : "disabled"}`}>
                    <div 
                        className={`parent-link ${expandedItem === elem.name ? 'expanded' : ''}`}
                        onClick={(e) => handleItemClick(elem.name, e)}
                    >
                        <span className="title-sliding">{elem.name}</span>
                    </div>
                    <div 
                        className={`sidebar-sub-links ${expandedItem === elem.name ? 'expanded' : ''}`}
                        data-active={getActiveView()}
                    >
                        {elem.children.map(child => (
                            <Link 
                                key={child.name} 
                                to={child.to || '#'} 
                                className={`sub-link ${isSubLinkActive(child.name.toLowerCase().split(' ')[0]) ? 'active' : ''}`}
                                onClick={(e) => {
                                    if (child.disabled) {
                                        e.preventDefault();
                                        return;
                                    }
                                    setExpandedItem(elem.name);
                                }}
                            >
                                {child.name}
                            </Link>
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <Link 
                key={elem.name} 
                to={elem.disabled ? '#' : elem.to} 
                onClick={(e) => {
                    if (elem.disabled) {
                        e.preventDefault();
                        return;
                    }
                    hoverOutSidebar();
                }}
                className={`sidebar-elems-link ${elem?.active ? "" : "disabled"}`}
            >
                <span className="title-sliding">{elem.name}</span>
            </Link>
        );
    });

    return (
        <div className="sidebar-menu-category">
            <div className="sidebar-category-container" onClick={handleCategoryClick}>
                {categoryIcon}
                <div className="sidebar-category-name title-sliding">{categoryName}</div>
            </div>
            <div className={`sidebar-elems-container ${expandedCategory && hovered ? 'expanded' : ''}`}>
                <div className={`sidebar-elems-grid`}>
                    {menuElems}
                </div>
            </div>
        </div>
    );
};

export default MenuCategory;